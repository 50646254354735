import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  UrlTree,
} from '@angular/router';
import { IdentityService } from '@dmc-ng/authentication';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

export const resetPasswordGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> => {
  const identityService = inject(IdentityService);
  const router = inject(Router);

  const mode = route.queryParamMap.get('mode');
  const oobCode = route.queryParamMap.get('oobCode');

  const isResetPasswordMode = mode === 'resetPassword' && !!oobCode;

  if (!isResetPasswordMode) {
    return of(router.createUrlTree(['/login']));
  }

  return of(identityService.user()).pipe(
    switchMap((user) => {
      if (user) {
        return from(identityService.signOut()).pipe(
          map(() =>
            router.createUrlTree(['/reset-password'], {
              queryParams: route.queryParams,
            }),
          ),
          catchError(() =>
            of(
              router.createUrlTree(['/reset-password'], {
                queryParams: route.queryParams,
              }),
            ),
          ),
        );
      } else {
        return of(true);
      }
    }),
  );
};
