<div
  class="dekuple-container d-flex justify-content-center align-items-center w-100 h-100"
>
  <div
    class="reset-password-card d-flex flex-column"
    [ngClass]="{
      'card-on-success-or-failed':
        !isCodeValid ||
        isPasswordSuccessfullyUpdated ||
        isPasswordFailedToBeUpdated
    }"
  >
    <div
      class="d-flex justify-content-center align-items-center dekuple-logo-top gap-2"
    >
      <mat-icon
        class="icons dekuple-logo-initial"
        svgIcon="logo"
      ></mat-icon>

      <mat-icon
        class="icons dekuple-logo-full"
        svgIcon="logo-name-fill"
      ></mat-icon>
    </div>

    @if (isCodeValid && !isPasswordSuccessfullyUpdated) {
      <div class="d-flex flex-column gap-2">
        <h3>Define a new password</h3>
        <div>
          <span class="description">
            Set your new password to access the platform for the user
          </span>
          <span class="email">{{ userEmail }}</span>
        </div>
      </div>

      <form
        [formGroup]="resetPasswordForm"
        (ngSubmit)="onSubmit()"
        class="d-flex flex-column gap-4"
      >
        <dmc-ng-input
          label="Enter password"
          [type]="canShowNewPassword ? 'password' : 'text'"
          formControlName="newPassword"
          customError="Password must meet the criteria"
        >
          <mat-icon
            class="icons password-icon material-icons-outlined"
            [fontIcon]="canShowNewPassword ? 'visibility_off' : 'visibility'"
            (click)="showNewPassword()"
          ></mat-icon>
        </dmc-ng-input>

        <dmc-ng-input
          label="Confirm password"
          [type]="canShowConfirmPassword ? 'password' : 'text'"
          formControlName="confirmPassword"
          customError="Passwords must match"
        >
          <mat-icon
            class="icons password-icon material-icons-outlined"
            [fontIcon]="
              canShowConfirmPassword ? 'visibility_off' : 'visibility'
            "
            (click)="showConfirmPassword()"
          ></mat-icon>
        </dmc-ng-input>

        <div class="d-flex flex-row gap-2 chips-container">
          <mat-chip
            [removable]="false"
            class="rounded"
            [ngClass]="{ 'valid-chip': isMinLengthValid }"
          >
            <div class="d-flex flex-row align-items-center gap-1 h-100">
              @if (isMinLengthValid) {
                <mat-icon
                  svgIcon="checkmark"
                  class="success"
                ></mat-icon>
              }
              <span class="chip-label">8 characters</span>
            </div>
          </mat-chip>

          <mat-chip
            [removable]="false"
            class="rounded"
            [ngClass]="{ 'valid-chip': isUppercaseValid }"
          >
            <div class="d-flex flex-row align-items-center gap-1 h-100">
              @if (isUppercaseValid) {
                <mat-icon
                  svgIcon="checkmark"
                  class="success"
                ></mat-icon>
              }
              <span class="chip-label">1 uppercase</span>
            </div>
          </mat-chip>

          <mat-chip
            [removable]="false"
            class="rounded"
            [ngClass]="{ 'valid-chip': isLowercaseValid }"
          >
            <div class="d-flex flex-row align-items-center gap-1 h-100">
              @if (isLowercaseValid) {
                <mat-icon
                  svgIcon="checkmark"
                  class="success"
                ></mat-icon>
              }
              <span class="chip-label">1 lowercase</span>
            </div>
          </mat-chip>

          <mat-chip
            [removable]="false"
            class="rounded"
            [ngClass]="{ 'valid-chip': isNumberValid }"
          >
            <div class="d-flex flex-row align-items-center gap-1 h-100">
              @if (isNumberValid) {
                <mat-icon
                  svgIcon="checkmark"
                  class="success"
                ></mat-icon>
              }
              <span class="chip-label">1 number</span>
            </div>
          </mat-chip>

          <mat-chip
            [removable]="false"
            class="rounded"
            [ngClass]="{ 'valid-chip': isSpecialCharValid }"
          >
            <div class="d-flex flex-row align-items-center gap-1 h-100">
              @if (isSpecialCharValid) {
                <mat-icon
                  svgIcon="checkmark"
                  class="success"
                ></mat-icon>
              }
              <span class="chip-label">1 special character</span>
            </div>
          </mat-chip>
        </div>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="resetPasswordForm.invalid"
          class="large full-width reset-password-btn"
        >
          <span class="label-button">Confirm</span>
        </button>
      </form>
    } @else if (!isCodeValid || isPasswordFailedToBeUpdated) {
      <div class="d-flex flex-column gap-2">
        <h3>Set new password</h3>
        <span class="description">
          @if (!isCodeValid) {
            Invalid or expired validation code. Please try again.
          } @else if (isPasswordFailedToBeUpdated) {
            An unexpected error ooccured. Please try again.
          }
        </span>
      </div>
      <button
        mat-raised-button
        color="primary"
        (click)="redirectToLogin()"
        class="large custom-btn"
      >
        <span class="label-button">Try again</span>
      </button>
    } @else if (isPasswordSuccessfullyUpdated) {
      <div class="d-flex flex-column gap-2">
        <h3>You’re all set to Login !</h3>
        <span class="description">
          You’re new password is ready for you to use.
        </span>
      </div>
      <button
        mat-raised-button
        color="primary"
        (click)="redirectToLogin()"
        class="large custom-btn"
      >
        <span class="label-button">Login</span>
      </button>
    }
  </div>

  <mat-icon
    class="icons dekuple-logo"
    svgIcon="logo-name"
  ></mat-icon>

  <div class="video-background">
    <video
      playsinline="playsinline"
      playsinline
      loop
      autoplay
      [muted]="true"
    >
      <source
        src="assets/bubles.mp4"
        type="video/mp4"
      />
    </video>
  </div>
</div>
